$primary: #87b734;
$secondary: #161820;
$success: #a9e147;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #c9cacb;
$white: #ffffff;
$dark: #41434c;

$light2: #cfd0d5;
$light3: #8b8d92;
$dark2: #08090e;

$body-bg: $secondary;
$body-color: $light; 

$font1: 'Bebas Neue', cursive;
$font2: 'Big Shoulders Text', cursive;
$font3: 'Poppins', sans-serif;

/* space */
$spacer: 1.6rem;

$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      2.4rem
    ),
    2: (
      3.2rem
    ),
    3: 4.8rem,
    4: (
      5.6rem
    ),
    5: (
      6.4rem
    )
  ),
  $spacers
);

/* fonts */
$font-family-sans-serif: $font3;
$headings-font-family: null;
$font-size-base: 1.6rem;
$line-height-lg: 1.5;
$line-height-sm: 1.2;
$small-font-size: 80%;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
$font-weight-base: $font-weight-light;
$line-height-base: 2;

$h1-font-size: 7rem;
$h2-font-size: 6rem;
$h3-font-size: 5rem;
$h4-font-size: 3.2rem;
$h5-font-size: 2.4rem;
$h6-font-size: 1.8rem;

$headings-margin-bottom: 2.4rem;
$headings-font-weight: 700;
$headings-line-height: 1.2;
$headings-color: $white;

$paragraph-margin-bottom: 1.6rem;

/* Links */
$link-color: $primary;
$link-decoration: none;
$link-hover-color: $info;
$link-hover-decoration: none;

/* Grid */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

/* Container */
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1148px,
  xxl: 1348px,
  xxxl: 1548px
);

/* Buttons */
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 3.8rem;
$input-btn-font-family: $font2;
$input-btn-font-size: 1.8rem;
$input-btn-line-height: 1;

$input-btn-border-width: 0;
$btn-font-weight: 700;

/* grid */
$grid-columns: 12;
$grid-gutter-width: 48px;

/* Btn disable box shadows */
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

/* border radius*/
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

@import "../bootstrap/scss/functions";
@import "../bootstrap/scss/variables";
@import "../bootstrap/scss/mixins";
@import "../bootstrap/scss/root";
@import "../bootstrap/scss/reboot";
@import "../bootstrap/scss/type";
@import "../bootstrap/scss/images";
@import "../bootstrap/scss/code";
@import "../bootstrap/scss/grid";
@import "../bootstrap/scss/tables";
@import "../bootstrap/scss/forms";
@import "../bootstrap/scss/buttons";
@import "../bootstrap/scss/transitions";
@import "../bootstrap/scss/dropdown";
@import "../bootstrap/scss/button-group";
@import "../bootstrap/scss/input-group";
@import "../bootstrap/scss/custom-forms";
@import "../bootstrap/scss/nav";
@import "../bootstrap/scss/navbar";
@import "../bootstrap/scss/card";
@import "../bootstrap/scss/breadcrumb";
@import "../bootstrap/scss/pagination";
@import "../bootstrap/scss/badge";
@import "../bootstrap/scss/jumbotron";
@import "../bootstrap/scss/alert";
@import "../bootstrap/scss/progress";
@import "../bootstrap/scss/media";
@import "../bootstrap/scss/list-group";
@import "../bootstrap/scss/close";
@import "../bootstrap/scss/toasts";
@import "../bootstrap/scss/modal";
@import "../bootstrap/scss/tooltip";
@import "../bootstrap/scss/popover";
@import "../bootstrap/scss/carousel";
@import "../bootstrap/scss/spinners";
@import "../bootstrap/scss/utilities";
@import "../bootstrap/scss/print";
